import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { FeatureFlagName } from '@shared/flags';

export const FeatureFlagContext = createContext<FeatureFlagName[]>([]);

interface FeatureFlagProviderProps {
  flags?: FeatureFlagName[];
}

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({
  children = null,
  flags = [],
}) => (
  <FeatureFlagContext.Provider value={flags}>
    {children}
  </FeatureFlagContext.Provider>
);

const useFeatureFlagContext = () => useContext(FeatureFlagContext);

/**
 * Returns a boolean indicating whether a given feature flag is enabled
 */
export const useFeatureFlag = (name: FeatureFlagName) => {
  const featureFlags = useFeatureFlagContext();

  return featureFlags.includes(name);
};

export interface FeatureFlagProps {
  name: FeatureFlagName;
  then: ReactNode;
  else: ReactNode;
}

/**
 * Conditionally render components based on the state of a given feature flag
 */
const FeatureFlag: FC<FeatureFlagProps> = ({
  name,
  then: thenExpr,
  else: elseExpr = null,
}) => {
  const hasFeature = useFeatureFlag(name);

  return <>{hasFeature ? thenExpr : elseExpr}</>;
};

export default FeatureFlag;

import UniversalRouter from 'universal-router';

import routes from './routes';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (
      context.route.featureFlag &&
      !context.featureFlags.includes(context.route.featureFlag)
    ) {
      context.next(false);
    }

    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then(action => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});

import PropTypes from 'prop-types';
import { Children } from 'react';

const App = ({ children }) => Children.only(children);

App.propTypes = {
  children: PropTypes.element.isRequired,
};

export default App;

import routeImportScheme from './routeImportScheme';
import routeMetadata from './routeMetadata';

const withContext = routePath => {
  if (!routePath) return '/:context?';
  return `/:context?${routePath.startsWith('/') ? '' : '/'}${routePath}`;
};

// Merge the route component import scheme with the route metadata and return a
// consolidated route map of all the child routes below the parent route
const children = routeMetadata.map(item => {
  const routeScheme = routeImportScheme[item.path] || routeImportScheme.base;
  return {
    ...item,
    path: withContext(item.path),
    load: routeScheme.load || undefined,
    children: routeScheme.children || undefined,
  };
});

// The top-level (parent) route
const routes = {
  path: '',
  children,

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - Anchore`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    action: require('./error').default,
  });
}

export default routes;
